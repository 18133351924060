<template>
  
  <div>
       <div class="header">
             <ul class="left_menu">
              <div class="item"> 
             
                      <li class="active a">发票管理</li>
                </div>
                 <div  class="item">  
                    <li class="a"><router-link to="/index9/xiaoxi">  <i class=" el-icon-message-solid" style="color:#0034FF;width:25px;height:25px;border-radius:100%;background: #f5f5f5;text-align:center;line-height:25px;margin-right:5px"></i>消息通知</router-link> </li>
                     <li style="cursor:pointer;" @click="dialogFormVisible = true">财务专员<i class="el-icon-caret-bottom"></i>  </li>
                </div>    

                                        <el-dialog title="个人信息" style="text-align:center" :visible.sync="dialogFormVisible">
                        <el-form :model="info">
                            <el-form-item label="昵称" :label-width="formLabelWidth">
                            <el-input v-model="info.name" autocomplete="off">  <el-button slot="append" @click="a11()">修改</el-button></el-input>
                         
                            </el-form-item>
                            <el-form-item label="登录账号" :label-width="formLabelWidth">
                             <el-input v-model="info.phone" autocomplete="off"  :disabled="true"></el-input>
                            </el-form-item>
                             <el-form-item label="登录密码" :label-width="formLabelWidth">
                             <el-input v-model="password3" placeholder="输入旧密码"  autocomplete="off">  <el-button slot="append"  @click="a22()">修改</el-button></el-input>
                            </el-form-item>
                            <el-form-item label="新密码" v-show="password2" :label-width="formLabelWidth">
                             <el-input v-model="password" autocomplete="off">  <el-button slot="append"  @click="a33()">确认修改</el-button> </el-input>
                            </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                              <el-button type="primary" @click="tuichu" style="width:100%">退出登录</el-button>
                        </div>
                        </el-dialog>  
             </ul>
        </div>
         <div class="search"></div>
            <div class="content">
               
                    <p style="margin:20px 0">发票列表</p>
                    
                          <el-table
                    :border="true"
                    :data="datal1"
                :header-cell-style="{background:'#FAFAFA'}"
                id="out-table"
                    style="width: 100%">
                    
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="id"
                    label="发票号"
                    width="100">
                    </el-table-column>
                    <el-table-column
                        prop="name" 
                    label="会员名称"
                        :show-overflow-tooltip="true" 
                    width="100">
                    </el-table-column>

                    <el-table-column
                    label="原始订单编号"
                    prop="orderid" 
                    
                    width="300">
                    
                    </el-table-column>
                    <el-table-column
                        prop="fee" 
                    label="成交金额（元）" 
                        :show-overflow-tooltip="true" 
                    width="100">
                
                    </el-table-column>

                        <el-table-column
                        prop="time" 
                    label="开具时间"
                        :show-overflow-tooltip="true" 
                        width="100"
                    >
                    </el-table-column>
                      <el-table-column
                        prop="title" 
                    label="发票抬头"
                        :show-overflow-tooltip="true" 
                        width="100"
                    >
                    </el-table-column>
                         <el-table-column
                        prop="email" 
                    label="接收邮箱"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
           

                    <el-table-column
                        fixed="right"
                        label="操作"
                      >
                        <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row)" type="success" size="small">下载打印</el-button>
                         
                        </template>
                        </el-table-column>
                </el-table>
            </div>
  </div>
</template>

<script>
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
export default {
 data(){
        return{
            datal1:[],
            datall:[],
                dialogFormVisible:false, form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        formLabelWidth: '120px',
        info:[],
infos:[],
            activeName: 'first',
    
                 tableData: [],
                    data1:[],
                    data2:[],
                 password:'',
                   password2:false,
                  password3:''
        }
        },
       methods:{
              tuichu(){
            this.dialogFormVisible = false;
            window.localStorage.removeItem('token')
                     this.$router.push({ name: 'login'})
        },
        a11(){
          this.$axios({
                        method: "post",
                        url: "/s/backend/api/userupdate",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:this.info,
                        }) .then(res=>{
                            this.dialogFormVisible = false
                              this.$message({
                   message:'修改成功',
                  type: 'success'
                     });
                        })
        .catch(function(){
           
        })  
        },
        a22(){
            this.password2=true
         
              
        },
        a33(){
               if(this.password ==''){
                this.$message({
                   message:'请输入新密码后点击修改',
                  type: 'success'
                     }); 
            }else{
                    this.$axios({
                            method: "post",
                            url: "/s/backend/api/changepass",
                            params: {
                            accessToken : localStorage.getItem('token')
                            },
                            data:{
                        "jobnumber":  localStorage.getItem('jobnumber'),
                        "newpassword": this.password,
                        "oldpassword": this.password3
                        },
                            }) .then(res=>{
                            
                            if(res.data.code == 0){
                                    this.dialogFormVisible = false
                                    this.$message({
                    message:'修改成功',
                    type: 'success'
                        });
                            }
                            })
            .catch(function(){
            
            })  
            }
        },
           handleClick(row){
            this.exportOrderData(row.email,row.title,row.fee)
           
           },
             exportOrderData(x,y,z) {
                 console.log(x,y,z)
       
   var _data = [

        [ "电子邮件",    "发票抬头", "金额" ],

        [   x,y, z ]

   
      ];
   
      const ws= XLSX.utils.aoa_to_sheet(_data);

      /* generate workbook and add the worksheet */
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */
      XLSX.writeFile(wb, '发票信息.xlsx');
      },
       },
        mounted(){
  this.$axios({
                        method: "post",
                        url: "/s/backend/api/cw_ptbenefit_statistic",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                     
                        }) .then(res=>{
                          this.infos = res.data.result
                          console.log(this.info)
                        })
    
 










   this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :10  }
        })

     .then(res=>{
    
         var data1 = res.data.data
   this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :9  }
        })

     .then(res=>{
     
     this.tableData=data1.concat(this.data2)

        })
        .catch(function(){
           
        })
        })
        .catch(function(){
           
        })

  this.$axios({
                        method: "get",
                        url: "/s/backend/api/cw_fp",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                         'page':1,
                          'limit':10
                        },
                     
                        }) .then(res=>{
                             this.datal1=res.data.data
                        })
    


    
         
  

               this.$axios.get("/s/backend/api/user_detail", {
        params: { 'accessToken':localStorage.getItem('token'),
        'jobnumber':localStorage.getItem('jobnumber')
        }
        })

     .then(res=>{
          this.info = res.data.data
           
        })
        .catch(function(){
           
        })
        }
}
</script>

<style scoped>

/deep/.el-dialog__wrapper{
    top:-50px
}

/deep/ .el-dialog{
        width: 25%;

    float: right;
  
}
    .content{
        background: #f5f5f5;
        padding:10px 40px;
    }
 .content .content_ul{
            display: flex;
            justify-content: space-between;
        }
         .content .content_ul li{
             width: 32%;padding-left: 15px;
         }




.item li:hover{
    color: #0034FF;
}
.item li a:hover{
    color: #0034FF !important;
}
   /deep/.el-table th > .cell {
      text-align: center;

    }

    /deep/.el-table .cell {
      text-align: center;

    }

/deep/.el-tabs--card>.el-tabs__header{
    border-bottom: none !important;
}

/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item{
    border-bottom:1px solid #E4E7ED;}
/deep/ .el-tabs__item.is-active{
    border:1px solid #409EFF;
    border-left:1px solid #409EFF !important;
      border-bottom:1px solid #409EFF !important;
}
    .header{
            min-height: 57px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
    .header .left_menu{
        display: flex;   width: 100%;  padding:0 40px;
        justify-content: space-between;
    }
    .header .left_menu .item{
        display: flex;
        align-items: center;
    }
        .header .left_menu .item .a{
            margin-right: 60px;  
            cursor: pointer;
        }
           .header .left_menu .item li a{
               text-decoration: none;color: #000;font-size: 16px;
font-weight: 500;
           }

        .header .active{
            color: #0034FF;
            position:relative;
        }
             .header .active::before{
                      content: "";
   
         left: 0;
    position: absolute;
    bottom:-120%;
    height:3px;
    width:30px;
    background: #0034FF;
            }
</style>